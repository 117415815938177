<template>
  <div class="box">
    <img src="@/img/company/bg.png" class="banner" />
    <div class="list-box">
      <div class="list" v-for="l in list" :key="l.companyId">
        <div class="name" @click="toInfo(l)">{{ l.companyName }}</div>
        <div
          :class="l.bindStatus == 0 ? 'button' : 'button checked'"
          @click="bind(l)"
        >
          {{ l.bindStatus == 0 ? "未绑定" : "已绑定" }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import url from "@/service/url-config";
import { Dialog, Toast } from "vant";
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$http.get(url.companyList2).then((res) => {
        this.list = res.data;
      });
    },
    toInfo(info) {
      this.$router.push({
        path: "myCompany",
        query: { info: JSON.stringify(info) },
      });
    },
    //绑定
    bind(info) {
      if (info.bindStatus == 1) {
        return;
      }
      Dialog.confirm({
        title: "温馨提示",
        message: `您确定要绑定 ${info.companyName} 为VIP认证企业吗?`,
      })
        .then(() => {
          this.$http
            .get(url.bindCompany + "?companyId=" + info.companyId)
            .then((res) => {
              if (res.code == 200) {
                info.bindStatus = 1;
                Toast("绑定成功");
              }
            });
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  padding-top: 80px;
  background: #f4f5f9;
  min-height: calc(100vh - 80px);
  .banner {
    width: 350px;
    height: 80px;
    display: block;
    margin: 0 auto;
  }
  .list-box {
    width: 375px;
    background: rgba(255, 255, 255, 0.99);
    border-radius: 10px;
    margin-top: 13px;
    .list {
      width: 346px;
      height: 42px;
      border-bottom: 1px solid #eeeeee;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name {
        color: #333333;
        font-size: 13px;
      }
      .button {
        width: 51px;
        height: 21px;
        background: #ebf0ff;
        border: 1px solid #4675f7;
        font-size: 10px;
        color: #4675f7;
        text-align: center;
        line-height: 21px;
        border-radius: 10px;
      }
      .checked {
        background: #f5f5f5;
        color: #999999;
        border: 1px solid #999999;
      }
    }
  }
}
</style>
